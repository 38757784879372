<template>
  <div class="section nobg nomargin home-trendingfund-sec">
    <div class="container">
      <div class="heading-block center">
        <h2>Featured M-Changa Fundraisers</h2>
      </div>
      <carousel
        :settings="settings"
        :wrap-around="true"
        :breakpoints="breakpoints"
        :items-to-show="1"
        :wrapAround="true"
        :autoplay="autoplay"
        class="carousel"
        v-if="trends"
      >
        <slide v-for="campaign in trends" :key="campaign">
          <div class="trending-item-wrapper w-full md:px-3">
            <div class="trending-image">
              <img :src="campaign.public_link" />
            </div>
            <div class="trending-card">
              <div class="trending-card-inner">
                <div
                  v-if="campaign.currency in country_codes"
                  class="text-center"
                >
                  <img
                    :src="this.country_flag[country_codes[campaign.currency]]"
                    :alt="country_codes[campaign.currency]"
                    class="mflag"
                  />
                </div>
                <div v-else class="text-center">
                  <img :src="this.country_flag['KE']" :alt="KE" class="mflag" />
                </div>
                <h3>
                  {{ campaign.campaign_name }}
                </h3>
                <p>
                  RAISED: {{ campaign.currency }}
                  {{
                    campaign.gross_amount.toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span
                    class="m_converted"
                    v-if="currencyConfig.currency_code != campaign.currency"
                  >
                    ({{ this.currencyConfig["currency_code"] }}
                    {{
                      campaign.ex_rates.display_amount.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }})
                  </span>
                  <br />Donations
                  {{
                    campaign.tot_count.toLocaleString("en", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  }}
                </p>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="'width:' + campaign.raised_perc + '%'"
                  >
                    <span>{{ campaign.raised_perc }}%</span>
                  </div>
                </div>
                <div class="goal-statistic">
                  <strong
                    >{{ campaign.currency }}
                    {{
                      campaign.gross_amount.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}</strong
                  >
                  funds raised of
                  <strong
                    >{{ campaign.currency }}
                    {{
                      campaign.target.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}</strong
                  >
                  goal
                </div>
                <div class="trending-footer">
                  <router-link
                    :to="'/fundraiser/' + campaign.transaction_id"
                    class="button button-outline"
                    >Donate</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      country_codes: { UGX: "UG", GHS: "GH", NGN: "NG", TZS: "TZ", ZMW: "ZM" },
      currencyConfig: [],
      trends: {},
      autoplay: 2000,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        autoplay: 2000,
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
          autoplay: 3000,
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
          autoplay: 3000,
        },
      },
    };
  },

  async created() {
    this.homeData();
  },
  methods: {
    homeData: async function () {
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/home-stats`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.changaStats = data;
          this.numberFundraisers = data.all_accounts;
          this.numberDonations = data.all_donations;
          this.npsScore = data.nps_score;
          this.currencyConfig = data.currencyConfig;
        });
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/trending`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.trends = data;
        });
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/search`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.campaignList = data;
        });
    },
  },
};
</script>

<style scoped>
.trending-item-wrapper .trending-card h3 {
  white-space: pre-line;
}
.trending-item-wrapper .trending-card .trending-card-inner {
  min-height: 330px;
}
.mflag {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 21px;
  padding-bottom: 3px;
}
.m_converted {
  width: 100%;
  display: inline-block;
  font-size: small;
  font-style: italic;
}
.carousel__prev,
.carousel__next {
  background-color: white;
  color: #666;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.carousel__prev:hover,
.carousel__next:hover {
  background-color: #fcc82c;
}
</style>